<template>
    <div class="modal-alert">
        <div class="head flex-row flex-between items-start">
            <div
                class="title"
                :class="options.titleClass"
                v-html="$translate(title)"/>
            <i @click="$emit('close')" class="zmdi zmdi-close"/>
        </div>
        <div class="body" v-html="body"/>
        <div class="buttons">
            <button
                @click="$emit('close')"
                class="btn btn-primary"
                v-html="$translate('CONFIRM')"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalAlert',
    props: ['options'],
    computed: {
        title() {
            return (this.options || {}).title
        },
        body() {
            return (this.options || {}).body
        },
    },
}
</script>
